module.exports = {
  SiteTitle: 'Owen Craston',
  Sitelogo: '#',
  SiteLogoText: 'Owen Craston',
  SiteAuthor: 'Owen Craston',
  SiteDescription: 'Developer',
  defaultDescription: 'Developer', 
  SiteSocialLinks: {
    github: 'https://github.com/owencraston',
    linkedin: 'https://www.linkedin.com/in/owencraston/',
    youtube: 'https://www.youtube.com/channel/UCliw7_YwIAlpH7hDmFTYANw'
  },
  SiteAddress: {
    city: 'Toronto',
    region: 'Ontario',
    country: 'Canada',
    zipCode: 'ZipCode',
  },
  SiteContact: {
    email: 'owengc12@gmail.com',
  },
  SiteCopyright: '2021',
};
